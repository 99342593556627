import './App.css';
// import '@fontsource/roboto/300.css';
// import '@fontsource/roboto/400.css';
// import '@fontsource/roboto/500.css';
// import '@fontsource/roboto/700.css';
import Grid from '@mui/material/Unstable_Grid2';
import PathButtons from "./components/pathButtons.js";
import SideButtons from "./components/sideButtons.js";
import MapWindow from "./components/mapWindowCore.js";
import { useState } from 'react';

function App() {
  const [selectedMap, changeMap ] = useState("osm/navigation");
  const [selectedRoute, changeRoute] = useState('none');

  const sideUpdate = (map)=>{
    changeMap(map);
  }

  const routeUpdate = (route)=>{
    changeRoute(route);
  }
  return (
    <div className="App">
      <Grid container>
        <Grid xs={1}><SideButtons updateFunc={sideUpdate}/></Grid>
        <Grid xs={11}><MapWindow map={selectedMap} route={selectedRoute}/></Grid>
      </Grid>
      <PathButtons updateFunc={routeUpdate}/>
    </div>
  );
}

export default App;
