import * as React from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

export default function SideButtons (props){
    return (
        <Stack spacing={2}>
            <Button onClick={()=>props.updateFunc("osm/navigation")}>Navigation</Button>
            <Button onClick={()=>props.updateFunc("osm/streets-relief")}>Street</Button>
            <Button onClick={()=>props.updateFunc("osm/hybrid")}>hybrid</Button>
        </Stack>
    )
}
