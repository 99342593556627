import Button from '@mui/material/Button';

export default function PathButtons (props){
    return (
        <div>
            <Button onClick={()=>props.updateFunc('round')}>Round</Button>
            <Button onClick={()=>props.updateFunc('out&back')}>Out & Back</Button>
            <Button onClick={()=>props.updateFunc('beeline')}>Bee Line</Button>
            <Button onClick={()=>props.updateFunc('all')}>All Buoys</Button>
        </div>
    )
}