// import "./index.css";
import Map from "@arcgis/core/Map";
import MapView from "@arcgis/core/views/MapView";
import esriConfig from "@arcgis/core/config.js";
import Graphic from "@arcgis/core/Graphic";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import { useEffect } from "react";

  export default function MapWindow(Props){
    function makeNewPoint(lat, long, color){
        const point = { //Create a point
            type: "point",
            longitude: lat,
            latitude: long
        };
        const simpleMarkerSymbol = {
            type: "simple-marker",
            color: color,
            outline: {
                color: [255, 255, 255], // White
                width: 1
            }
        };
        const pointGraphic = new Graphic({
            geometry: point,
            symbol: simpleMarkerSymbol
        });
        return pointGraphic
    }

    function makeNewLine(path, color){
        const polyline = {
            type: "polyline",  // autocasts as new Polyline()
              paths: path
          };
          
          const polylineSymbol = {
            type: "simple-line",  // autocasts as SimpleLineSymbol()
            color: color,
            width: 4
          };
          
          const polylineAtt = {
            Name: "Round Course",
            Owner: "RGYC"
          };
          
          const polylineGraphic = new Graphic({
            geometry: polyline,
            symbol: polylineSymbol,
            attributes: polylineAtt
          });

          return polylineGraphic
    }

    useEffect(()=>{
        esriConfig.apiKey= "AAPK1781009da94e42f19975f4f5756dd6a8-2OewQ6uYJWL1roB06KdJcFcvbTq8nlQN_z2CRyMS7XctUq_NqweWp-exZfMtiqI";
        const map = new Map({
            basemap: Props.map
        });
        
        const view = new MapView({
            container: "viewDiv", // reference the div id
            map: map,
            zoom: 14,
            center: [-107.19085577802542, 33.208106568767334]
        });
        const buoyLayer = new GraphicsLayer();
        const lineLayer = new GraphicsLayer();
        map.add(lineLayer);
        map.add(buoyLayer);

        // Add buoy Markers
        const greenZero = makeNewPoint(-107.190083, 33.201222, [54, 175, 93])
        const greenOne = makeNewPoint(-107.195806, 33.205639, [54, 175, 93])
        const greenTwo = makeNewPoint(-107.199083, 33.211139, [54, 175, 93])
        const greenThree = makeNewPoint(-107.195806, 33.220000, [54, 175, 93])
        const greenFour = makeNewPoint(-107.198533, 33.225200, [54, 175, 93])  // This one is way off circle
        const greenFive = makeNewPoint(-107.182056, 33.220389, [54, 175, 93])
        const redSeven = makeNewPoint(-107.180472, 33.213028, [226, 119, 40])
        const redEight = makeNewPoint(-107.185000, 33.206917, [226, 119, 40])
        const centerPin = makeNewPoint(-107.190668, 33.211785, [212, 211, 210])
        
        // Add Race routes
        const round = makeNewLine([[-107.190083, 33.201222], [-107.199083, 33.211139], [-107.180472, 33.213028], [-107.190083, 33.201222]], [230, 235, 176])
        const bLine = makeNewLine([[-107.190668, 33.211785], [-107.198533, 33.225200]], [230, 235, 176])
        const outNback = makeNewLine([[-107.190083, 33.201222], [-107.190668, 33.211785]], [230, 235, 176])

        // post routes
        switch(Props.route){
            case "round":
                lineLayer.add(round);
                break;
            case "out&back":
                lineLayer.add(outNback);
                break;
            case "beeline":
                lineLayer.add(bLine);
                break;
            default:
                break;
        }

        // post points
        switch(Props.route){
            case "round":
                buoyLayer.add(greenZero);
                buoyLayer.add(greenTwo);
                buoyLayer.add(redSeven);
                buoyLayer.add(centerPin);
                break;
            case "out&back":
                buoyLayer.add(centerPin);
                buoyLayer.add(greenZero);
                break;
            case "beeline":
                buoyLayer.add(greenFour);
                buoyLayer.add(greenThree);
                buoyLayer.add(centerPin);
                break;
            default:
                buoyLayer.add(greenZero);
                buoyLayer.add(greenOne);
                buoyLayer.add(greenTwo);
                buoyLayer.add(greenThree);
                buoyLayer.add(greenFour);
                buoyLayer.add(greenFive);
                buoyLayer.add(redSeven);
                buoyLayer.add(redEight);
                buoyLayer.add(centerPin);
                break;
        }

    }, [Props.map, Props.route])

    return (
        <div id="viewDiv"></div>
    )
  }